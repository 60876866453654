.page-header-title {
	background-color: #263238;
	padding: 10px 15px 100px 20px;
	-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.page-header-title .breadcrumb {
	margin-bottom: 0;
}

.page-header-title .page-title {
	margin-bottom: 12px;
	color: #ffffff;
	font-size: 22px;
}

.page-content-wrapper {
	margin-top: -100px;
}
/* Register royalty club css */
.card{
	/* margin-left: 7px; */
	width: 100%;
	height: 100%;
	min-height: 295px;
	border-radius: 12px;
}
.menu_font{
	text-transform: capitalize;
}
.club_info{
top: 25%;
}
.v-card__text{
	text-align: center;
}
.bold{
	font-weight: bold;
}
.club_logo_top{
  /* position: absolute; */
  /* top: -60px; */
  margin-top: 15px;
  left: 36%;
  width: 110px !important;
  height: 110px;
}

/* Summernote */
.note-editor {
	position: relative;
}

.note-editor .btn-default {
	background-color: transparent;
	border-color: transparent !important;
}

.note-editor .btn-group-sm>.btn,
.note-editor .btn-sm {
	padding: 8px 12px !important;
}

.note-editor .note-toolbar {
	background-color: #f3f3f3;
	border-bottom: 1px solid #eeeeee;
	margin: 0;
}

.note-editor .note-statusbar {
	background-color: #ffffff;
}

.note-editor .note-statusbar .note-resizebar {
	border-top: none;
	height: 15px;
	padding-top: 3px;
}

.note-editor.note-frame {
	border: 1px solid #eeeeee !important;
}

.card {
	border: none;
	-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
	margin-bottom: 20px;
}

.v-data-table .theme--light.v-icon,
.v-data-table .theme--dark.v-icon {
	opacity: 0.9;
}

.v-data-table .theme--light.v-icon {
	color: #4caf50;
	caret-color: #4caf50;
}

* {
	outline: none !important;
}

.business-layout .v-list-item__action,.v-list-item__icon {
    margin-right: 12px;
    margin-left: 15px;
}

.menu_font{
	font-size: 17px!important;
	line-height: 1.5rem!important;
}

.sub_menu_font{
	font-size: 16px!important;
	line-height: 1.5rem!important;
}

.menu_height .v-list-item {
    min-height: 55px!important;
}

.menu_icon_mt .v-list-item__icon{
	margin-top: 15px!important;
}

.skip_btn, .pre_btn, .tui-image-editor-cancel-btn {
    color: #5DCAC8 !important;
    /* background-color: #ffffff !important;
    border: 1px solid #5DCAC8!important; */
	background-color: #FFFFFF !important;
    border: 2px solid #00bcd4 !important;
    box-shadow: none!important;
	font-size: 17px!important;
	border-radius: 7px!important;
}
.pre_btn {
	padding-right: 12px!important;
    padding-left: 4px!important;
}

.next_btn, .save_btn, .tui-image-editor-apply-btn{
	color: #ffffff !important;
	font-size: 17px!important;
	background-color: #00bcd4 !important;
    border: 2px solid #00bcd4 !important;
	box-shadow: none!important;
	/* background-color: #5DCAC8!important;
	border: 1px solid #ffffff!important; */
	border-radius: 7px!important;
}
.save_btn{
	padding-left: 20px!important;
    padding-right: 20px!important;
}
