.dataTable {

	th,
	td {
		vertical-align: middle;
	}

	.checkbox {
		line-height: 1;

		label {
			margin-bottom: 0;
		}

		input[type="checkbox"] {
			bottom: 0 !important;
		}
	}
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 99999;

	#preloader-inner {
		position: absolute;
		left: 50%;
		top: 50%;
		font-size: 5px;
		width: 5em;
		height: 5em;
		margin: -5em 0 0 -5em;
		text-indent: -9999em;
		border-top: 0.5em solid #2a323c;
		border-right: 0.5em solid rgba(245, 245, 245, 0.5);
		border-bottom: 0.5em solid rgba(245, 245, 245, 0.5);
		border-left: 0.5em solid rgba(245, 245, 245, 0.5);
		transform: translateZ(0);
		animation: loading 1.1s infinite linear;
	}

	#preloader-inner,
	#preloader-inner::after {
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
}

.custom-loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 99999;

	.inner {
		position: absolute;
		left: 50%;
		top: 50%;
		font-size: 5px;
		width: 5em;
		height: 5em;
		margin: -5em 0 0 -5em;
		text-indent: -9999em;
		border-top: 0.25em solid #fff;
		border-right: 0.25em solid rgba(245, 245, 245, 0.25);
		border-bottom: 0.25em solid rgba(245, 245, 245, 0.25);
		border-left: 0.25em solid rgba(245, 245, 245, 0.25);
		transform: translateZ(0);
		animation: loading 1.1s infinite linear;
	}

	.inner,
	.inner::after {
		border-radius: 50%;
	}
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// .sign-bg {
// 	background-image: url(../../public/assets/images/banner/Pos_banner.jpg);
// 	background-color: white;
// 	position: absolute;
// 	height: 100%;
// 	width: 100%;
// 	top: 0;

// 	&::after {
// 		content: '';
// 		position: absolute;
// 		width: 100%;
// 		height: 100%;
// 		top: 0;
// 		background-color: rgba(0, 0, 0, 0.5);
// 	}
// }

.sign-bg {
	background-image: url(../../public/assets/images/banner/background.jpg);
	background-color: white;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	background-size: cover;
	background-position: initial;
	background-repeat: no-repeat;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		//background-color: rgba(245, 220, 220, 0.1);
		// rgba(245, 214, 214, 0.1);
	}
}

.signup-bg {
	background-image: url("../../public/assets/images/banner/signup_banner.jpg") !important;
	background-color: white;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: rgba(245, 220, 220, 0.1);
	}
}

.member-bg {
	background-image: url("../../public/assets/images/banner/Pos_banner.jpg") !important;
	background-color: white !important;
	position: absolute !important;
	height: 100% !important;
	width: 100% !important;
	top: 0 !important;
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: rgba(245, 220, 220, 0.1);
	}
}

.header-sign {
	background: 0 0;
	position: fixed;
	z-index: 1040;
	height: 81px;
	// padding: 0 40px;
	top: 0;
	left: 0;
	box-shadow: none;
	// border-bottom: 1px solid rgba(220, 220, 220, .2);
	width: 100%;
	transition: height .3s ease-out, background .3s ease-out, transform .5s, box-shadow .5s, left .2s cubic-bezier(.645, .045, .355, 1), -webkit-transform .5s;

	.brand-dark {
		display: none;
	}

	.brand-light {
		display: block;
	}

	.container-fluid {
		position: relative;
		display: table;
		height: 100%;
	}

	.inner-header,
	.nav-toggle {
		display: table-cell;
		height: 100%;
	}

	.inner-navigation {
		height: 100%;
	}

	.inner-header {
		// min-width: 160px;
		max-width: 160px;
		overflow: hidden;
		z-index: 999;
		vertical-align: middle;
	}

	.inner-navigation,
	.nav-toggle {
		width: 100%;
	}

	.nav {
		justify-content: center;

		@media (max-width: 991px) {
			flex-direction: column;
			justify-content: flex-start;
		}

		li {
			position: relative;

			a {
				padding: 30px 20px;
			}
		}

		.menu-item-has-children {
			.sub-menu {
				background-color: #fff;
				position: absolute;
				min-width: 190px;
				transition: all 0.4s;
				padding: 10px 0;
				display: none;
				top: 100%;
				box-shadow: 0 14px 32px 4px rgba(0, 0, 0, .1);

				a {
					position: relative;
					display: block;
					padding: 8px 20px;
					letter-spacing: 1px;
					font-weight: 500;
					font-size: 12px;
					color: #999;
					transition: all 0.4s;
					white-space: nowrap;

					&:hover {
						transform: translateX(5px);
						color: #777;
					}
				}
			}

			&:hover {
				.sub-menu {
					display: block;
				}
			}
		}
	}

	.nav-toggle {
		display: none;

		@media (max-width: 991px) {
			display: table-cell;
			vertical-align: middle;
			text-align: right;
		}
	}

	&.header-small {
		background-color: #fff;
		height: 61px;

		.brand-dark {
			display: block;
		}

		.brand-light {
			display: none;
		}

		.nav {
			li {
				a {
					padding: 20px 20px;
					color: #1b1d1f !important;
				}
			}
		}
	}

	&.header-shadow {
		box-shadow: 0 0 5px rgba(0, 0, 0, .1);
		height: 61px;
	}

	@media (min-width: 992px) {
		.inner-navigation.collapse {
			display: table-cell !important;
		}
	}

	@media (max-width: 991px) {
		background-color: #fff;
		padding: 0;

		.brand-dark {
			display: block;
		}

		.brand-light {
			display: none;
		}

		.inner-navigation {
			position: absolute;
			background-color: #1b1d1f;
			height: auto;
			max-height: 340px;
			width: 100%;
			left: 0;
			top: 100%;
		}

		.nav li a {
			padding: 10px 20px;
		}

		&.header-small {
			.nav {
				li {
					a {
						color: #fff !important;
						padding: 10px 20px;
					}
				}
			}
		}

		.nav li:hover {
			.sub-menu {
				background-color: #1b1d1f;
				position: static;
				display: block;

				a {
					transform: translateX(5px);
					color: #fff !important;
				}
			}
		}
	}
}

.td-wrap {
	white-space: pre-wrap !important;
	word-break: break-all !important;
}
