input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
}

.v-application--wrap,
.v-dialog__content {
	label {
		margin-bottom: 0;
	}
	.v-input--selection-controls__ripple {
		z-index: 1;
	}
}
